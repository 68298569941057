import React from 'react';
import HyperLink from 'components/Core/HyperLink';
import WebsiteImage from 'components/WebsiteImage';

const AdSection = ({ adData }) => (
  <div className="invoice-gen--ad-container">
    <div className="invoice-gen--ad-content">
      {adData?.image?.image && (
        <>
          <WebsiteImage image={adData?.image?.image} alt={adData?.image.alt} />
          {adData?.url && <HyperLink href={adData?.url} target="_blank" />}
        </>
      )}
    </div>
  </div>
);

export default AdSection;
