import React from 'react';
import { InvoiceInput, InvoiceDatePicker } from './styles';

const DetailSection = ({ invoiceValues, onChange, ...otherProps }) => {
  return (
    <div className="invoice-gen--detail-container invoice-gen--info-container">
      <div className="invoice-gen--info">
        <p>Invoice No: </p>
        <InvoiceInput
          className="bold-font"
          onChange={(e) => onChange('invoiceNo', e.target.value)}
          value={invoiceValues.invoiceNo}
          placeholderText="#"
          showOnlyFormError
        />
      </div>
      <div className="invoice-gen--info">
        <p>Invoice Date: </p>
        <InvoiceDatePicker
          label="Select date"
          id="invoice-date"
          onChange={(e) => onChange('invoiceDate', e.target.value)}
          value={invoiceValues.invoiceDate}
          renderInputProps={{ error: false }}
        />
      </div>
      <div className="invoice-gen--info">
        <p>Due Date: </p>
        <InvoiceDatePicker
          label="Select date"
          id="due-date"
          onChange={(e) => onChange('dueDate', e.target.value)}
          value={invoiceValues.dueDate}
          renderInputProps={{ error: false }}
        />
      </div>
    </div>
  );
};

export default DetailSection;
