import React from 'react';
import { STIcon } from 'components/Brand/Icons/STIcon';
import Button from 'components/Core/Button';
import { InvoiceInput } from './styles';

const TableSection = ({
  invoiceItems,
  onItemChange,
  addInvoiceItem,
  removeInvoiceItem,
  ...otherProps
}) => {
  const floatToDollar = (val) =>
    `$${parseFloat(val.length === 0 ? 0 : val)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  return (
    <div className="invoice-gen--bill-table-container">
      <div className="invoice-gen--bill-table-row bill-header">
        <h4 className="invoice-gen--bill-column" />
        <h4 className="invoice-gen--bill-column">Service / Materials</h4>
        <h4 className="invoice-gen--bill-column">Quantity</h4>
        <h4 className="invoice-gen--bill-column">Unit Cost</h4>
        <h4 className="invoice-gen--bill-column">Line Total</h4>
      </div>

      {invoiceItems.map((item, index) => (
        <div className="invoice-gen--bill-table-row bill-body" key={index}>
          <div className="invoice-gen--bill-column mobile-header">
            <h4>Line Items</h4>
            <STIcon type="icon-trash" onClick={() => removeInvoiceItem(index)} />
          </div>
          <div className="invoice-gen--bill-column">
            <p className="invoice-gen--bill-column-mobile-header">Item Name :</p>
            <InvoiceInput
              id="name"
              onChange={(e) => onItemChange(index, 'name', e.target.value)}
              value={item.name}
              placeholderText="Describe your service or material ordered"
              showOnlyFormError
            />
          </div>
          <div className="invoice-gen--bill-column">
            <p className="invoice-gen--bill-column-mobile-header">Quantity :</p>
            <InvoiceInput
              id="quantity"
              type="number"
              className="align-center"
              onChange={(e) => onItemChange(index, 'quantity', e.target.value)}
              value={item.quantity}
              placeholderText="0"
              decimalScale={2}
              showOnlyFormError
            />
          </div>
          <div className="invoice-gen--bill-column">
            <p className="invoice-gen--bill-column-mobile-header">Unit Cost :</p>
            <InvoiceInput
              id="cost"
              type="currency"
              className="align-center"
              onChange={(e) => onItemChange(index, 'cost', e.target.value)}
              value={item.cost}
              decimalScale={2}
              thousandSeparator
              showOnlyFormError
            />
          </div>
          <div className="invoice-gen--bill-column">
            <p className="desktop-text">{floatToDollar(item.total)}</p>
            <p className="mobile-text">Item Total : {floatToDollar(item.total)}</p>
          </div>
          <div className="invoice-gen--bill-remove-row">
            <STIcon
              type="icon-close-x"
              onClick={() => removeInvoiceItem(index)}
              width={15}
              height={15}
            />
          </div>
        </div>
      ))}

      <div className="invoice-gen--bill-table-button">
        <Button type="White" onClick={() => addInvoiceItem()} shadow={false}>
          Add a line item
        </Button>
      </div>
    </div>
  );
};

export default TableSection;
