import React, { useState, useEffect, useRef } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import Cookies from 'js-cookie';
import { saveForm, init } from 'services/marketoService';
import TextField from 'components/Core/TextField';
import HyperLink from 'components/Core/HyperLink';
import { STIcon } from 'components/Brand/Icons/STIcon';
import Button from 'components/Core/Button';
import { STLogo } from 'components/Brand/Logos/STLogo';
import Popover from 'components/Core/Popover';
import Select from 'components/Core/Select';
import useIndustries from 'hooks/useIndustries';
import GeneratePdf from './GeneratePdf';
import DetailSection from './DetailSection';
import AddressSection from './AddressSection';
import TableSection from './TableSection';
import BillNoteSection from './BillNoteSection';
import AdSection from './AdSection';
import SignatureSection from './SignatureSection';
import ThankyouSection from './ThankyouSection';

import { FormContainer } from '../styles';
import {
  InvoiceContainer,
  DownloadContainer,
  DownloadModalContainer,
  ColorWheelContainer,
  ColorBlock,
} from './styles';

import invoiceModalImg from '../../../images/tmp-invoice-3.png';

const InvoiceGeneratorForm = ({ ...props }) => {
  const marketoFormId = 2147;

  const [isUpdate, setUpdate] = useState(false);
  const [additionalNoteEnable, setAdditionalNoteEnable] = useState(false);
  const [taxEnable, setTaxEnable] = useState(false);
  const [serviceEnable, setServiceEnable] = useState(false);
  const [thankyouEnable, setThankyouEnable] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [colorValue, setColorValue] = useState('var(--titan-blue-6)');
  const companyInput = useRef(null);
  const { industryList } = useIndustries();
  const [invoiceValues, setInvoiceValues] = useState({
    invoiceNo: '',
    invoiceDate: '',
    dueDate: '',
    fromName: Cookies.get('bill_from_name') || '',
    fromAddress: Cookies.get('bill_from_street') || '',
    fromCity: Cookies.get('bill_from_city') || '',
    fromPhone: Cookies.get('bill_from_phone') || '',
    fromEmail: Cookies.get('bill_from_email') || '',
    toName: '',
    toAddress: '',
    toCity: '',
    toPhone: '',
    toEmail: '',
    serviceName: '',
    serviceTechName: '',
    serviceTechNumber: '',
    serviceAddress: '',
    serviceCity: '',
    additionalNote: '',
    thankyou: '',
    subTotal: 0,
    tax: 8,
    customerName: '',
    customerSignature: '',
    customerDate: '',
    technicianName: '',
    technicianSignature: '',
    technicianDate: '',
    companyLogo: null,
    invoiceColor: `#${colorValue}`,
  });

  const [invoiceItems, setInvoiceItems] = useState([
    {
      name: '',
      quantity: '',
      cost: '',
      total: '',
    },
  ]);

  const [downFormValues, setDownFormValues] = useState({
    email: Cookies.get('email') || '',
    industry: '',
    agree: true,
  });

  const [downFormValid, setDownFormValid] = useState({
    email: 0,
    industry: 0,
  });

  const checkDownloadFormValid = () => {
    const validation = downFormValid;
    validation.email = downFormValues.email !== '' ? 1 : -1;
    validation.industry = downFormValues.industry !== '' ? 1 : -1;
    setDownFormValid(validation);
    return Object.keys(validation).filter((field) => validation[field] === 1).length === 2;
  };

  const dollarToFloat = (val) =>
    val.length > 0 ? parseFloat(val.replace('$', '').replace(',', '')) : 0;

  const onChange = (id, value) => {
    const tempValues = { ...invoiceValues };
    if (id !== 'tax') {
      tempValues[id] = value;
    } else {
      tempValues[id] = dollarToFloat(value);
      tempValues[id] = tempValues[id] === 0 ? '' : tempValues[id];
    }
    setInvoiceValues(tempValues);
  };

  useEffect(() => {
    init(marketoFormId);
  }, []);

  const calcTotal = (changedIndex = -1, changedTotal = 0) => {
    let subTotal = 0;
    invoiceItems.forEach((item, index) => {
      const itemTotal = parseFloat(index !== changedIndex ? item.total : changedTotal);
      subTotal += itemTotal || 0;
    });
    onChange('subTotal', subTotal);
  };

  const onItemChange = (index, id, value) => {
    const tempItems = [...invoiceItems];

    tempItems[index][id] = value;
    if (id === 'quantity' || id === 'cost') {
      tempItems[index].total =
        dollarToFloat(tempItems[index].quantity) * dollarToFloat(tempItems[index].cost);
    }

    setInvoiceItems(tempItems);
  };

  const addInvoiceItem = () => {
    const tempItems = [...invoiceItems];
    tempItems.push({
      name: '',
      quantity: '',
      cost: '',
      total: '',
    });
    setInvoiceItems(tempItems);
  };

  const removeInvoiceItem = (index) => {
    const tempItems = [...invoiceItems];
    tempItems.splice(index, 1);
    setInvoiceItems(tempItems);
  };

  const handleLogoChange = (event) => {
    const tempValues = { ...invoiceValues };
    tempValues.companyLogo = URL.createObjectURL(event.target.files[0]);
    setInvoiceValues(tempValues);
  };

  const onFormChange = (id, value) => {
    setDownFormValues({
      ...downFormValues,
      [id]: value,
    });
  };

  const clickDownloadPdf = async (cookieValues = null) => {
    if (showDownloadModal && !checkDownloadFormValid()) {
      setUpdate(!isUpdate);
      return;
    }

    const submitData = {
      event: 'calculator',
      form_type: 'Invoice Generator',
      company: invoiceValues.fromName,
      ...(cookieValues || downFormValues),
      page_title: props.page_title,
      url: props.url,
    };

    dataLayer.push({
      ...submitData,
    });

    try {
      await saveForm({
        ...submitData,
        marketoFormId,
      });

      if (submitData.email !== invoiceValues.fromEmail) {
        await saveForm({
          ...submitData,
          email: invoiceValues.fromEmail,
          marketoFormId,
        });
      }
    } catch (e) {
      console.log(e);
    }

    if (!cookieValues) {
      Cookies.set('invoice_email', downFormValues.email);
      Cookies.set('email', downFormValues.email);
      Cookies.set('invoice_industry', downFormValues.industry);
    }

    Cookies.set('bill_from_name', invoiceValues.fromName);
    Cookies.set('bill_from_street', invoiceValues.fromAddress);
    Cookies.set('bill_from_city', invoiceValues.fromCity);
    Cookies.set('bill_from_phone', invoiceValues.fromPhone);
    Cookies.set('bill_from_email', invoiceValues.fromEmail);
    Cookies.set('bill_logo', invoiceValues.companyLogo);

    const filteredItems = invoiceItems
      .filter((item) => dollarToFloat(item.quantity) > 0 && dollarToFloat(item.cost) > 0)
      .map((item) => {
        return {
          ...item,
          quantity: dollarToFloat(item.quantity),
          cost: dollarToFloat(item.cost),
        };
      });

    GeneratePdf(
      'invoice',
      {
        ...invoiceValues,
        serviceEnable,
        additionalNoteEnable,
        thankyouEnable,
        taxEnable,
      },
      filteredItems,
    );

    setShowDownloadModal(false);
  };

  const clickDownload = async () => {
    const cookieValues = {
      email: Cookies.get('invoice_email'),
      industry: Cookies.get('invoice_industry'),
    };

    if (cookieValues.email) {
      await clickDownloadPdf(cookieValues);
    } else {
      onFormChange('email', invoiceValues.fromEmail);
      setShowDownloadModal(true);
    }
  };

  useEffect(() => {
    calcTotal();
  }, [invoiceItems]);

  useEffect(() => {
    onChange('invoiceColor', colorValue);
  }, [colorValue]);

  const getColorWheel = () => {
    const colors = [
      '#FF6900',
      '#FCB900',
      '#7BDCB5',
      '#00D084',
      '#8ED1FC',
      '#0693E3',
      '#ABB8C3',
      '#EB144C',
      '#F78DA7',
      '#9900EF',
    ];

    return (
      <ColorWheelContainer>
        {colors.map((color) => (
          <ColorBlock color={color} onClick={() => setColorValue(color)} />
        ))}
        <div className="color-input">
          <div>#</div>
          <input
            type="text"
            value={colorValue?.slice(1)}
            onChange={(event) => setColorValue(`#${event.target.value}`)}
          />
        </div>
      </ColorWheelContainer>
    );
  };

  return (
    <>
      <DownloadContainer>
        <div className="invoice-gen--download">
          <Popover content={getColorWheel()} PaperProps={{ style: { marginTop: '20px' } }}>
            <STIcon type="icon-color-wheel" />
          </Popover>
          <Button type="secondary" shadow={false} onClick={clickDownload}>
            Download Now
          </Button>
        </div>
      </DownloadContainer>
      <FormContainer {...props} style={{ marginTop: 0 }}>
        <div className="section-tool-page-form--wrapper">
          <div className="section-tool-page-form--container" style={{ maxWidth: '100%' }}>
            <InvoiceContainer invoiceColor={colorValue}>
              <div className="invoice-gen--container">
                <div className="invoice-gen--header" />
                <div className="invoice-gen--header-mobile-color">
                  <Popover content={getColorWheel()} PaperProps={{ style: { marginTop: '20px' } }}>
                    <STIcon type="icon-color-wheel" />
                  </Popover>
                </div>
                <div className="invoice-gen--main">
                  <div className="invoice-gen--title-container">
                    <h2 className="invoice-gen--title">Invoice</h2>
                  </div>

                  <div className="invoice-gen--company-logo">
                    <div
                      className="invoice-gen--company-logo-content"
                      onClick={() => companyInput.current.click()}
                    >
                      <input
                        type="file"
                        ref={companyInput}
                        onChange={handleLogoChange}
                        style={{ display: 'none' }}
                      />
                      {invoiceValues.companyLogo ? (
                        <img src={invoiceValues.companyLogo} alt="" />
                      ) : (
                        <p>Add your logo</p>
                      )}
                    </div>
                  </div>

                  <DetailSection invoiceValues={invoiceValues} onChange={onChange} />

                  <AddressSection
                    invoiceValues={invoiceValues}
                    onChange={onChange}
                    serviceEnable={serviceEnable}
                    setServiceEnable={setServiceEnable}
                  />

                  <TableSection
                    invoiceItems={invoiceItems}
                    onItemChange={onItemChange}
                    addInvoiceItem={addInvoiceItem}
                    removeInvoiceItem={removeInvoiceItem}
                  />

                  <BillNoteSection
                    invoiceValues={invoiceValues}
                    onChange={onChange}
                    additionalNoteEnable={additionalNoteEnable}
                    setAdditionalNoteEnable={setAdditionalNoteEnable}
                    taxEnable={taxEnable}
                    setTaxEnable={setTaxEnable}
                  />

                  {props.adData && <AdSection adData={props.adData} />}

                  <SignatureSection invoiceValues={invoiceValues} onChange={onChange} />

                  <ThankyouSection
                    invoiceValues={invoiceValues}
                    onChange={onChange}
                    thankyouEnable={thankyouEnable}
                    setThankyouEnable={setThankyouEnable}
                  />
                </div>
                <div className="invoice-gen--footer">
                  <STLogo />
                </div>
              </div>

              <div className="invoice-gen--mobile-download">
                <Button type="secondary" shadow={false} onClick={clickDownload}>
                  Download Now
                </Button>
              </div>
            </InvoiceContainer>
          </div>
        </div>
      </FormContainer>

      <DownloadModalContainer
        className="invoice-generator-modal-container"
        open={showDownloadModal}
        handleClose={() => setShowDownloadModal(false)}
      >
        <div className="invoice-gen--download-modal-container">
          <div className="invoice-gen--download-modal-marketo-container">
            <h4 className="invoice-gen--download-modal-marketo-title">
              Your invoice is being generated. Simply answer the questions below to finish up!
            </h4>
            <div className="invoice-gen--download-modal-marketo">
              <TextField
                id="email"
                className="invoice-gen--download-modal-marketo-field"
                type="text"
                placeholderText={'Email'}
                formValid={downFormValid}
                values={downFormValues}
                handleChange={onFormChange}
                shadow
                showOnlyFormError
              />

              <Select
                id="industry"
                className="invoice-gen--download-modal-marketo-field"
                MenuProps={{ PopoverClasses: { paper: 'modal' } }}
                options={industryList}
                placeholder="Select industry..."
                values={downFormValues}
                formValid={downFormValid}
                handleChange={onFormChange}
                showOnlyFormError
              />
              <FormControlLabel
                className="invoice-gen--download-modal-marketo-field"
                control={
                  <Checkbox
                    checked={downFormValues.agree}
                    onChange={(e) => onFormChange('agree', e.target.checked)}
                  />
                }
                label={
                  <>
                    Learn more about{' '}
                    <HyperLink href="/field-service-management-software" target="_blank">
                      ServiceTitans accounting software
                    </HyperLink>
                  </>
                }
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '14px',
                    textAlign: 'left',
                  },
                }}
              />
            </div>
            <Button
              className="invoice-gen--download-modal-marketo-button"
              shadow={false}
              onClick={() => clickDownloadPdf()}
            >
              Download Now
            </Button>
          </div>
          <div className="invoice-gen--download-modal-ad-container">
            <img src={invoiceModalImg} />
            <p className="invoice-gen--download-modal-ad-description">
              Get paid in the field, in the office, or online. Streamline your shop’s most
              time-consuming accounting tasks with ServiceTitan Payments.
            </p>
          </div>
        </div>
      </DownloadModalContainer>
    </>
  );
};

export default InvoiceGeneratorForm;
