import React from 'react';

const SignatureSection = ({ invoiceValues, onChange, ...otherProps }) => {
  return (
    <div className="invoice-gen--signature-container">
      <div className="invoice-gen--signature-row">
        <div className="invoice-gen--signature-column">
          <input
            className="invoice-gen--signature-input"
            onChange={(e) => onChange('customerName', e.target.value)}
            value={invoiceValues.customerName}
          />
          <p>Customer Name</p>
        </div>
        <div className="invoice-gen--signature-column">
          <input
            className="invoice-gen--signature-input"
            onChange={(e) => onChange('customerSignature', e.target.value)}
            value={invoiceValues.customerSignature}
          />
          <p>Customer Signature</p>
        </div>
        <div className="invoice-gen--signature-column">
          <input
            className="invoice-gen--signature-input"
            onChange={(e) => onChange('customerDate', e.target.value)}
            value={invoiceValues.customerDate}
          />
          <p>Date</p>
        </div>
      </div>
      <div className="invoice-gen--signature-row">
        <div className="invoice-gen--signature-column">
          <input
            className="invoice-gen--signature-input"
            onChange={(e) => onChange('technicianName', e.target.value)}
            value={invoiceValues.technicianName}
          />
          <p>Technician Name</p>
        </div>
        <div className="invoice-gen--signature-column">
          <input
            className="invoice-gen--signature-input"
            onChange={(e) => onChange('technicianSignature', e.target.value)}
            value={invoiceValues.technicianSignature}
          />
          <p>Technician Signature</p>
        </div>
        <div className="invoice-gen--signature-column">
          <input
            className="invoice-gen--signature-input"
            onChange={(e) => onChange('technicianDate', e.target.value)}
            value={invoiceValues.technicianDate}
          />
          <p>Date</p>
        </div>
      </div>
    </div>
  );
};

export default SignatureSection;
