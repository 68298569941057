import React, { useEffect, useState } from 'react';
import MUISwitch from '@mui/material/Switch';

const Switch = ({ checked, defaultChecked, onChange, otherProps }) => {
  const [isChecked, toggle] = useState(defaultChecked || checked || false);

  useEffect(() => {
    if (checked !== null && checked !== undefined) toggle(checked);
  }, [checked]);

  const handleOnChange = (value) => {
    toggle(value);
    if (onChange) onChange(value);
  };

  return (
    <MUISwitch checked={isChecked} onChange={() => handleOnChange(!isChecked)} {...otherProps} />
  );
};

export default Switch;
