import React from 'react';
import { graphql } from 'gatsby';
import { globalHistory } from '@reach/router';
import Hero from 'components/Tools/Hero';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import ToolboxLayout from 'layouts/toolboxLayout';
import ContentfulCardsBlock from 'components/Contentful/ContentfulCardsBlock';
import InvoiceGeneratorForm from 'components/Tools/InvoiceGenerator/Form';
import ContentfulConversionPanel from 'components/Contentful/ContentfulConversionPanel';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';
import BookmarkContext from 'utils/BookmarkContext';

const InvoiceGeneratorPage = (props) => {
  const data = props.data.contentfulTool;
  const adData = props.data.contentfulAd;
  const breadcrumbs = createToolsBreadcrumbs('Invoice Generator');

  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} noOverlap />
        <InvoiceGeneratorForm
          adData={adData}
          page_title={data.seo?.pageTitle}
          url={globalHistory.location.href}
        />
        <DescriptionBlock data={data.description} />
        {data.cardsBlock && <ContentfulCardsBlock {...data.cardsBlock} />}
        {data.conversionPanel && <ContentfulConversionPanel {...data.conversionPanel} />}
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default InvoiceGeneratorPage;

export const InvoiceGeneratorPageQuery = graphql`
  query InvoiceGeneratorPageQuery {
    contentfulTool(internalName: { eq: "Invoice Generator" }) {
      ...Tool
    }
    contentfulAd(internalName: { eq: "Ad - Invoice Generator" }) {
      ...Ads
    }
  }
`;
