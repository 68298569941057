import React, { useEffect, useRef } from 'react';
import Switch from 'components/Core/Switch';
import Button from 'components/Core/Button';
import { InvoiceInput, InvoiceTextArea } from './styles';

const BillNoteSection = ({
  invoiceValues,
  onChange,
  additionalNoteEnable,
  setAdditionalNoteEnable,
  taxEnable,
  setTaxEnable,
  ...otherProps
}) => {
  const taxInput = useRef(null);

  useEffect(() => {
    if (taxEnable) {
      taxInput.current.focus();
    }
  }, [taxEnable]);

  return (
    <div className="invoice-gen--bill-note-container">
      <div
        className={`invoice-gen--additional-note-container invoice-gen--info-container ${
          !additionalNoteEnable ? 'disabled' : ''
        }`}
      >
        <h4 className="invoice-gen--additional-note-title">Additional Notes:</h4>
        <div onClick={() => setAdditionalNoteEnable(true)}>
          <InvoiceTextArea
            className={`invoice-gen--additional-note-description ${
              !additionalNoteEnable ? 'disabled' : ''
            }`}
            onChange={(e) => onChange('additionalNote', e.target.value)}
            value={invoiceValues.additionalNote}
            disabled={!additionalNoteEnable}
            rows={2}
            placeholder="Please provide additional notes or information regarded the service/materials you invoiced for."
          />
        </div>
        <Switch
          checked={additionalNoteEnable}
          onChange={() => setAdditionalNoteEnable(!additionalNoteEnable)}
        />
      </div>
      <div className="invoice-gen--total-container">
        <div className="invoice-gen--sub-total-info">
          <div className="invoice-gen--sub-total-title">
            <h4>Subtotal</h4>
          </div>
          <div className="invoice-gen--sub-total-value">
            <InvoiceInput
              id="subTotal"
              type="number"
              className="align-right disabled"
              value={invoiceValues.subTotal.toFixed(2)}
              decimalScale={2}
              thousandSeparator
              showOnlyFormError
              disabled
            />
          </div>
        </div>
        <div className="invoice-gen--sub-total-info">
          <div className="invoice-gen--sub-total-title">
            <h4>Sales Tax&nbsp;</h4>
            <h4>(</h4>
            <InvoiceInput
              id="tax"
              type="number"
              className={`align-center bold-font ${!taxEnable ? 'disabled' : ''}`}
              onChange={(e) => onChange('tax', e.target.value)}
              value={invoiceValues.tax}
              suffix="%"
              decimalScale={0}
              showOnlyFormError
              disabled={!taxEnable}
              width="55px"
              getInputRef={taxInput}
              onBlur={() => setTaxEnable(false)}
              style={{ marginTop: 2 }}
            />
            <h4>)</h4>
            <Button type="white" shadow={false} onClick={() => setTaxEnable(true)}>
              Edit
            </Button>
          </div>
          <div className="invoice-gen--sub-total-value">
            <InvoiceInput
              id="tax"
              type="number"
              className="align-right disabled"
              value={parseFloat((invoiceValues.subTotal * invoiceValues.tax) / 100).toFixed(2)}
              decimalScale={2}
              thousandSeparator
              showOnlyFormError
              disabled
              width="90px"
            />
          </div>
        </div>
        <div className="invoice-gen--total-info">
          <div className="invoice-gen--total-title">
            <h4>Total</h4>
          </div>
          <div className="invoice-gen--total-value">
            <InvoiceInput
              id="total"
              type="number"
              className="align-right disabled"
              value={parseFloat(
                invoiceValues.subTotal + (invoiceValues.subTotal * invoiceValues.tax) / 100,
              ).toFixed(2)}
              decimalScale={2}
              thousandSeparator
              showOnlyFormError
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillNoteSection;
