import React from 'react';
import { MUITextField } from 'components/Core/TextField/styles';

const DatePicker = ({ id, label, value, onChange, renderInputProps }) => (
  <MUITextField
    id={id}
    label={label}
    type="date"
    onChange={onChange}
    value={value}
    sx={{ width: 220 }}
    variant="filled"
    InputProps={{ disableUnderline: true, ...renderInputProps }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

export default DatePicker;
