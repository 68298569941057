import React from 'react';
import Switch from 'components/Core/Switch';
import { InvoiceInput } from './styles';

const AddressSection = ({
  invoiceValues,
  onChange,
  serviceEnable,
  setServiceEnable,
  ...otherProps
}) => {
  const disabled = !serviceEnable ? 'disabled' : '';
  return (
    <div className="invoice-gen--bill-address-container">
      <div className="invoice-gen--address-container invoice-gen--info-container">
        <h4 className="invoice-gen--address-title">Bill From:</h4>
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('fromName', e.target.value)}
          value={invoiceValues.fromName}
          placeholderText="Business Name"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('fromAddress', e.target.value)}
          value={invoiceValues.fromAddress}
          placeholderText="Street Address"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('fromCity', e.target.value)}
          value={invoiceValues.fromCity}
          placeholderText="City, State, Zip Code"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('fromPhone', e.target.value)}
          value={invoiceValues.fromPhone}
          placeholderText="Phone Number"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('fromEmail', e.target.value)}
          value={invoiceValues.fromEmail}
          placeholderText="Business Email"
          showOnlyFormError
        />
      </div>
      <div className="invoice-gen--address-container invoice-gen--info-container">
        <h4 className="invoice-gen--address-title">Bill To:</h4>
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('toName', e.target.value)}
          value={invoiceValues.toName}
          placeholderText="Client Name"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('toAddress', e.target.value)}
          value={invoiceValues.toAddress}
          placeholderText="Street Address"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('toCity', e.target.value)}
          value={invoiceValues.toCity}
          placeholderText="City, State, Zip Code"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('toPhone', e.target.value)}
          value={invoiceValues.toPhone}
          placeholderText="Phone Number"
          showOnlyFormError
        />
        <InvoiceInput
          className="invoice-gen--address-info"
          onChange={(e) => onChange('toEmail', e.target.value)}
          value={invoiceValues.toEmail}
          placeholderText="Business Email"
          showOnlyFormError
        />
      </div>
      <div className={`invoice-gen--address-container invoice-gen--info-container ${disabled}`}>
        <h4 className="invoice-gen--address-title">Service Info:</h4>
        <div onClick={() => setServiceEnable(true)}>
          <InvoiceInput
            className={`invoice-gen--address-info ${disabled}`}
            onChange={(e) => onChange('serviceName', e.target.value)}
            value={invoiceValues.serviceName}
            placeholderText="Name"
            disabled={!serviceEnable}
            showOnlyFormError
          />
          <InvoiceInput
            className={`invoice-gen--address-info ${disabled}`}
            onChange={(e) => onChange('serviceTechName', e.target.value)}
            value={invoiceValues.serviceTechName}
            placeholderText="Tech Name"
            disabled={!serviceEnable}
            showOnlyFormError
          />
          <InvoiceInput
            className={`invoice-gen--address-info ${disabled}`}
            onChange={(e) => onChange('serviceTechNumber', e.target.value)}
            value={invoiceValues.serviceTechNumber}
            placeholderText="Tech Number"
            disabled={!serviceEnable}
            showOnlyFormError
          />
          <InvoiceInput
            className={`invoice-gen--address-info ${disabled}`}
            onChange={(e) => onChange('serviceAddress', e.target.value)}
            value={invoiceValues.serviceAddress}
            placeholderText="Street Address"
            disabled={!serviceEnable}
            showOnlyFormError
          />
          <InvoiceInput
            className={`invoice-gen--address-info ${disabled}`}
            onChange={(e) => onChange('serviceCity', e.target.value)}
            value={invoiceValues.serviceCity}
            placeholderText="City/State/Zip"
            disabled={!serviceEnable}
            showOnlyFormError
          />
        </div>
        <Switch checked={serviceEnable} onChange={() => setServiceEnable(!serviceEnable)} />
      </div>
    </div>
  );
};

export default AddressSection;
