import styled from 'styled-components';
import TextField from 'components/Core/TextField';
import Modal from 'components/Core/Modal';
import DatePicker from 'components/Core/DatePicker';

export const InvoiceContainer = styled.div`
  padding: 25px 0;

  .MuiSwitch-root {
    .MuiSwitch-thumb {
      color: var(--white);
    }
    .Mui-checked + .MuiSwitch-track {
      opacity: 1;
      background-color: ${(props) => `${props.invoiceColor}` || 'var(--titan-blue-6)'};
    }
  }

  .invoice-gen--container {
    max-width: 1196px;
    margin: 0 auto;

    .invoice-gen--header {
      height: 32px;
      background: ${(props) => props.invoiceColor || 'var(--titan-blue-6)'};
    }

    .invoice-gen--header-mobile-color {
      display: none;
    }

    .invoice-gen--main {
      padding: 37px 48px 36px 55px;
      background: var(--white);
      border: 1px solid var(--grey-4);
      position: relative;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-family: Arial !important;
        color: var(--darkest);
        line-height: var(--line-height-110);
        letter-spacing: var(--letter-spacing--0-01);
        margin: 0;
        padding: 0;
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-700);
      }

      p {
        line-height: var(--line-height-150);
      }

      .invoice-gen--title-container {
        max-width: 800px;

        .invoice-gen--title {
          font-weight: var(--font-weight-700);
          font-size: var(--font-size-35);
          margin: 0;
        }
      }

      .invoice-gen--company-logo {
        border: 1px dashed var(--grey-4);
        box-sizing: border-box;
        border-radius: 2px;
        position: absolute;
        width: 231px;
        height: 142px;
        top: 17px;
        right: 21px;
        padding: 20px;

        .invoice-gen--company-logo-content {
          width: 100%;
          height: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          p {
            font-size: var(--font-size-20);
            font-weight: var(--font-weight-700);
            color: var(--grey-5);
            margin: 0;
          }

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .invoice-gen--detail-container {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        max-width: 900px;
      }

      .invoice-gen--bill-address-container {
        display: flex;
        margin: 10px -11px 0;

        .invoice-gen--address-container {
          padding: 20px;
          flex: 1;
          margin: 0 11px;

          .invoice-gen--address-info {
            margin: 12px 0 0;
          }
        }
      }

      .invoice-gen--bill-table-container {
        margin-top: 20px;

        h4 {
          margin: 0;
        }

        .invoice-gen--bill-table-row {
          background: var(--grey-3);
          border: 1px solid var(--grey-4);
          box-sizing: border-box;
          border-radius: 2px;
          display: flex;

          .invoice-gen--bill-column {
            padding: 14px 20px;

            p {
              margin: 0;
            }

            &:nth-child(1) {
              display: none;
            }

            &:nth-child(2) {
              flex: 4;
            }

            &:nth-child(3),
            &:nth-child(4) {
              flex: 1;
              text-align: center;
            }

            &:nth-child(5) {
              flex: 1;
              text-align: right;
            }
          }

          &.bill-body {
            background: white;
            border: none;
            border-bottom: 1px solid var(--grey-4);
            position: relative;

            .invoice-gen--bill-column {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &:nth-child(5) {
                justify-content: flex-end;

                .mobile-text {
                  display: none;
                  font-size: var(--font-size-16);
                }
              }

              &.mobile-dropdown {
                display: none;
              }

              .invoice-gen--bill-column-mobile-header {
                display: none;
              }
            }

            .invoice-gen--bill-remove-row {
              width: 30px;
              height: 30px;
              background: var(--white);
              border: 1px solid var(--grey-3);
              box-sizing: border-box;
              box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              position: absolute;
              right: -41px;
              top: 15px;
            }
          }
        }

        .invoice-gen--bill-table-button {
          margin-top: 20px;
          text-align: center;

          a {
            color: var(--titan-blue-3);
          }
        }
      }

      .invoice-gen--bill-note-container {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        .invoice-gen--additional-note-container {
          padding: 20px;
          margin-right: 15px;
          width: 100%;
          max-width: 632px;

          .invoice-gen--additional-note-title {
            font-weight: var(--font-weight-700);
            font-size: var(--font-size-20);
          }

          .invoice-gen--additional-note-description {
            font-size: var(--font-size-20);
            line-height: var(--line-height-150);
            margin: 12px 0 0;
          }

          &.disabled {
            opacity: 0.4;
          }
        }

        .invoice-gen--total-container {
          min-width: 361px;

          .invoice-gen--sub-total-info {
            padding: 0 14px 16px;
            background: white;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .invoice-gen--sub-total-title {
              display: flex;
              align-items: center;

              h4 {
                font-weight: var(--font-weight-700);
                font-size: var(--font-size-20);
                line-height: 32px;
              }

              button {
                width: 36px;
                height: 30px;
                color: var(--titan-blue-3);
                margin-left: 10px;
                margin-top: 5px;
                padding: 0;
              }
            }

            .invoice-gen--sub-total-value p {
              font-size: var(--font-size-20);
            }
          }

          .invoice-gen--total-info {
            background: var(--grey-3);
            border: 1px solid var(--grey-4);
            box-sizing: border-box;
            border-radius: 2px;
            padding: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .invoice-gen--total-title h4 {
              font-weight: var(--font-weight-700);
              font-size: var(--font-size-20);
            }

            .invoice-gen--total-value p {
              font-size: var(--font-size-20);
            }
          }
        }
      }

      .invoice-gen--ad-container {
        margin-top: 74px;
        display: flex;
        justify-content: flex-end;

        .invoice-gen--ad-content {
          height: 235px;
          background: #f4f5f8;
          font-size: var(--font-size-28);
          font-weight: var(--font-weight-900);
          color: var(--grey-4);
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .gatsby-image-wrapper {
            max-width: 490px;
            max-height: -webkit-fill-available;
          }
          img {
            width: auto;
            height: 100%;
          }

          a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }

      .invoice-gen--signature-container {
        margin-top: 15px;

        .invoice-gen--signature-row {
          display: flex;

          p {
            margin: 0;
          }

          .invoice-gen--signature-column {
            flex: 344;
            margin: 0 20px;

            .invoice-gen--signature-input {
              width: 100%;
              border: none;
              border-bottom: 1px solid var(--darkest);
              height: 40px;
              font-size: var(--font-size-20);
              padding: 10px 0;

              &:focus {
                outline: none;
                border-bottom: 2px solid var(--titan-blue-3);
              }
            }

            p {
              font-size: var(--font-size-20);
              margin-top: 15px;
            }

            &:last-child {
              flex: 208;
            }
          }
        }
      }

      .invoice-gen--thankyou-container {
        max-width: 846px;
        margin: 41px auto 0;
        text-align: center;

        .invoice-gen--thankyou-description {
          max-width: 634px;
          margin: 0 auto;
        }
      }

      .invoice-gen--info-container {
        box-sizing: border-box;
        border-radius: 2px;
        padding: 20px;
        position: relative;

        h4 {
          font-size: var(--font-size-20);
          font-weight: var(--font-weight-700);
          margin: 0;
        }

        p {
          font-size: var(--font-size-20);
          margin: 0;
        }

        .invoice-gen--info {
          display: flex;
          min-width: 0;
          margin-right: 15px;

          p {
            white-space: nowrap;
            align-self: center;
            margin-right: 3px;
          }
        }

        .MuiSwitch-root {
          position: absolute;
          right: 25px;
          top: 19px;
        }

        &.disabled {
          opacity: 0.4;
        }
      }
    }

    .invoice-gen--footer {
      height: 121px;
      background: ${(props) => props.invoiceColor || 'var(--titan-blue-6)'};
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 234px;
      }
    }
  }

  .invoice-gen--mobile-download {
    display: none;
    margin: 30px 0 50px;
  }

  @media (max-width: 1140px) {
    .invoice-gen--container {
      .invoice-gen--main {
        .invoice-gen--company-logo {
          width: 163px;
          height: 70px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .invoice-gen--container {
      .invoice-gen--main {
        padding: 17px 21px;

        .invoice-gen--company-logo {
          display: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .invoice-gen--container {
      position: relative;

      .invoice-gen--header-mobile-color {
        display: block;
        position: absolute;
        z-index: 10;
        right: 20px;
        top: 9px;
        width: 50px;
        height: 50px;
        background: var(--white);
        border: 0.25px solid var(--grey-4);
        border-radius: 50%;
        box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 70%;
          height: 70%;
        }
      }

      .invoice-gen--main {
        padding: 24px 20px;

        .invoice-gen--detail-container {
          flex-direction: column;
          padding: 20px 0;

          .invoice-gen--info {
            margin-bottom: 5px;

            p {
              font-size: var(--font-size-16);
              width: 100px;
            }

            .text-field-wrapper {
              width: calc(100% - 100px);
              height: 33px;
              padding: 3px 5px;

              .text-field-input {
                height: 33px !important;
              }
            }
          }
        }

        .invoice-gen--bill-address-container {
          flex-direction: column;
          margin: 10px 0;

          .invoice-gen--address-container {
            margin: 0 0 30px;
            padding: 0;
          }
        }

        .invoice-gen--bill-table-container {
          .invoice-gen--bill-table-row {
            &.bill-header {
              display: none;
            }

            &.bill-body {
              flex-direction: column;
              margin-bottom: 30px;

              .invoice-gen--bill-column {
                padding: 5px 10px;

                p {
                  text-align: right;
                }

                h4 {
                  display: block;
                }

                &:nth-child(5) {
                  border-top: 1px solid var(--grey-4);
                  justify-content: flex-end;

                  .desktop-text {
                    display: none;
                  }

                  .mobile-text {
                    display: block;
                    padding: 10px 0;
                  }
                }

                &.mobile-dropdown {
                  display: flex;

                  span {
                    cursor: pointer;
                  }
                }

                .invoice-gen--bill-column-mobile-header {
                  display: block;
                  font-size: var(--font-size-16);
                  font-weight: var(--font-weight-700);
                  min-width: 100px;
                  text-align: right;
                  margin-right: 15px;
                }
              }

              .invoice-gen--bill-remove-row {
                display: none;
              }
            }
          }
        }

        .invoice-gen--bill-note-container {
          flex-direction: column;
          margin-top: 20px;

          h4 {
            margin: 0;
          }

          .invoice-gen--additional-note-container {
            margin: 0 0 20px;
            padding: 20px 0;

            .invoice-gen--additional-note-description {
              font-size: var(--font-size-16);
            }
          }

          .invoice-gen--total-container {
            min-width: 0;

            .invoice-gen--sub-total-info {
              .invoice-gen--sub-total-title {
                h4 {
                  font-size: var(--font-size-16);
                  line-height: 20px;
                }

                .text-field-wrapper.align-center .text-field-input {
                  text-align: center;
                }
              }
            }
          }
        }

        .invoice-gen--ad-container {
          display: none;
        }

        .invoice-gen--signature-container {
          .invoice-gen--signature-row {
            flex-direction: column;

            .invoice-gen--signature-column {
              margin: 0 5px;
            }
          }
        }

        .invoice-gen--thankyou-container {
          .invoice-gen--thankyou-description {
            font-size: var(--font-size-16);
          }
        }

        .invoice-gen--info-container {
          .invoice-gen--info {
            margin-right: 0;
          }

          .MuiSwitch-root {
            right: 0px;
            top: -10px;
          }
        }
      }
    }

    .invoice-gen--mobile-download {
      display: block;

      button,
      a {
        width: 100%;
      }
    }
  }
`;

export const DownloadContainer = styled.div`
  padding: 18px 28px;
  border-bottom: 1px solid var(--grey-3);

  .invoice-gen--download {
    max-width: 1196px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;

    svg {
      &:hover {
        cursor: pointer;
      }
    }

    a,
    button {
      margin-left: 26px;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const DownloadModalContainer = styled(Modal)`
  .modal-container {
    max-width: 1026px !important;
    width: 100%;
  }

  .invoice-gen--download-modal-container {
    display: flex;

    .invoice-gen--download-modal-marketo-container {
      flex: 560;
      padding: 37px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .invoice-gen--download-modal-marketo-title {
        font-size: var(--font-size-25);
        text-align: center;
        font-weight: var(--font-weight-900);
        margin: 0;
      }

      .invoice-gen--download-modal-marketo {
        margin-top: 40px;

        .invoice-gen--download-modal-marketo-field {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
        }
      }

      .invoice-gen--download-modal-marketo-button {
        width: 100%;
        margin-top: 20px;
      }
    }

    .invoice-gen--download-modal-ad-container {
      flex: 466;
      padding: 37px;
      background: var(--grey-1);
      display: flex;
      flex-direction: column;
      justify-content: center;

      .invoice-gen--download-modal-ad-description {
        font-size: var(--font-size-16);
        color: var(--grey-8);
        text-align: center;
        margin: 0;
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
  }
`;

export const ColorWheelContainer = styled.div`
  background: white;
  padding: 15px 9px 9px 15px;
  width: 276px;
  height: 96px;

  .color-input {
    div {
      background: rgb(240, 240, 240);
      height: 30px;
      width: 30px;
      border-radius: 4px 0px 0px 4px;
      float: left;
      color: rgb(152, 161, 164);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      width: 100px;
      font-size: 14px;
      color: rgb(102, 102, 102);
      border: 0px;
      outline: none;
      height: 28px;
      box-shadow: rgb(240, 240, 240) 0px 0px 0px 1px inset;
      box-sizing: content-box;
      border-radius: 0px 4px 4px 0px;
      float: left;
      padding-left: 8px;
    }
  }
`;

export const ColorBlock = styled.div`
  background: ${({ color }) => color};
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative;
  outline: none;
  float: left;
  border-radius: 4px;
  margin: 0px 6px 6px 0px;
`;

export const InvoiceInput = styled(TextField)`
  ${(props) =>
    props.width
      ? `
    width: ${props.width} !important;
  `
      : `
    width: 100% !important;
  `}

  &.disabled {
    &:hover {
      outline: none;
    }
  }

  @media (max-width: 767px) {
    &.text-field-wrapper.align-center .text-field-input {
      text-align: left;
    }
  }
`;

export const InvoiceTextArea = styled.textarea`
  border: none;
  background: transparent;
  font-size: var(--font-size-20);
  font-family: Arial;
  letter-spacing: var(--letter-spacing--0-01);
  color: var(--darkest);
  min-width: 0;
  padding: 0 5px;

  ${(props) =>
    props.width
      ? `
    width: ${props.width};
  `
      : `
    width: 100%;
  `}

  &:hover {
    outline: 1px solid #ddd;
  }

  &:focus {
    outline: 1px solid var(--titan-blue-3);
  }

  &.bold-font {
    font-weight: var(--font-weight-700);
  }

  &.disabled {
    &:hover {
      outline: none;
    }
  }
`;

export const InvoiceDatePicker = styled(DatePicker)``;

export const pdfStyle = {
  part: {
    flex: 1,
    padding: 15,
  },
  card: {
    height: 150,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flex: 1,
    padding: 15,
  },
};
