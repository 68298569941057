import React from 'react';
import Switch from 'components/Core/Switch';
import { InvoiceTextArea } from './styles';

const ThankyouSection = ({
  invoiceValues,
  onChange,
  thankyouEnable,
  setThankyouEnable,
  ...otherProps
}) => {
  return (
    <div
      className={`invoice-gen--thankyou-container invoice-gen--info-container ${
        !thankyouEnable ? 'disabled' : ''
      }`}
    >
      <div onClick={() => setThankyouEnable(true)}>
        <InvoiceTextArea
          className={`invoice-gen--thankyou-description ${!thankyouEnable ? 'disabled' : ''}`}
          onChange={(e) => onChange('thankyou', e.target.value)}
          value={invoiceValues.thankyou}
          disabled={!thankyouEnable}
          placeholder="Please provide thank you message here"
        />
      </div>
      <Switch checked={thankyouEnable} onChange={() => setThankyouEnable(!thankyouEnable)} />
    </div>
  );
};

export default ThankyouSection;
