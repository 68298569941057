import React from 'react';
import { Page, Text, View, Document, Image, Font, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ArialRegular from '../../../../static/fonts/ArialRegular.ttf';
import ArialBold from '../../../../static/fonts/ArialBold.ttf';
import imgLogo from '../../../images/st-logo-horizontal.png';
import { pdfStyle } from './styles';

const GeneratePdf = async (fileName, invoiceInfo, invoiceItems) => {
  const textSize = 12;

  const SingleText = ({ children, size = textSize, style = {}, bold = false }) => {
    return (
      <Text
        style={{
          fontFamily: 'Arial',
          fontSize: size,
          ...(bold && { fontWeight: 'bold' }),
          ...style,
        }}
      >
        {children}
      </Text>
    );
  };

  const SingleField = ({ title = '', content = '', style = {} }) => {
    return (
      <View style={{ width: '100%', ...style }}>
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            marginBottom: 10,
            paddingBottom: 5,
          }}
        >
          <SingleText>{content}</SingleText>
        </View>
        <SingleText>{title}</SingleText>
      </View>
    );
  };

  Font.register({
    family: 'Arial',
    fonts: [{ src: ArialRegular }, { src: ArialBold, fontStyle: 'normal', fontWeight: 'bold' }],
  });

  const floatToDollar = (val) =>
    `$${parseFloat(val.length === 0 ? 0 : val)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  try {
    const blob = await pdf(
      <Document>
        <Page size="A4" style={{ paddingTop: 50, paddingBottom: 80 }}>
          <View
            fixed
            style={{
              position: 'absolute',
              top: 0,
              height: 20,
              marginBottom: 20,
              width: '100%',
              backgroundColor: invoiceInfo.invoiceColor,
            }}
          />
          <View style={{ paddingHorizontal: 35 }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 4, marginRight: 25, marginBottom: 7 }}>
                <View style={{ marginBottom: 15 }}>
                  <SingleText bold size={20}>
                    Invoice
                  </SingleText>
                </View>
                <View style={{ ...pdfStyle.part, width: '100%', flexDirection: 'row' }}>
                  <View style={{ flexDirection: 'row', width: 110 }}>
                    <SingleText bold>Invoice No: </SingleText>
                    <SingleText>{invoiceInfo.invoiceNo}</SingleText>
                  </View>
                  <View style={{ flexDirection: 'row', width: 150 }}>
                    <SingleText bold>Invoice Date: </SingleText>
                    <SingleText>{invoiceInfo.invoiceDate}</SingleText>
                  </View>
                  <View style={{ flexDirection: 'row', width: 100 }}>
                    <SingleText bold>Due Date: </SingleText>
                    <SingleText>{invoiceInfo.dueDate}</SingleText>
                  </View>
                </View>
              </View>
              <View
                style={{ width: 100, height: 60, flexDirection: 'row', justifyContent: 'flex-end' }}
              >
                {invoiceInfo.companyLogo && (
                  <Image
                    src={invoiceInfo.companyLogo}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                )}
              </View>
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 12 }}
            >
              <View style={{ ...pdfStyle.card }}>
                <SingleText bold>Bill From:</SingleText>
                <SingleText>{invoiceInfo.fromName}</SingleText>
                <SingleText>{invoiceInfo.fromAddress}</SingleText>
                <SingleText>{invoiceInfo.fromCity}</SingleText>
                <SingleText>{invoiceInfo.fromPhone}</SingleText>
                <SingleText>{invoiceInfo.fromEmail}</SingleText>
              </View>
              <View style={{ width: 10 }} />
              <View style={{ ...pdfStyle.card }}>
                <SingleText bold>Bill To:</SingleText>
                <SingleText>{invoiceInfo.toName}</SingleText>
                <SingleText>{invoiceInfo.toAddress}</SingleText>
                <SingleText>{invoiceInfo.toCity}</SingleText>
                <SingleText>{invoiceInfo.toPhone}</SingleText>
                <SingleText>{invoiceInfo.toEmail}</SingleText>
              </View>
              {invoiceInfo.serviceEnable && (
                <>
                  <View style={{ width: 10 }} />
                  <View style={{ ...pdfStyle.card }}>
                    <SingleText bold>Service Info:</SingleText>
                    <SingleText>{invoiceInfo.serviceName}</SingleText>
                    <SingleText>{invoiceInfo.serviceTechName}</SingleText>
                    <SingleText>{invoiceInfo.serviceTechNumber}</SingleText>
                    <SingleText>{invoiceInfo.serviceAddress}</SingleText>
                    <SingleText>{invoiceInfo.serviceCity}</SingleText>
                  </View>
                </>
              )}
            </View>
            <View style={{ marginBottom: 30 }}>
              <View
                style={{
                  flexDirection: 'row',
                  padding: '8px 15px',
                  backgroundColor: 'var(--grey-3)',
                  borderWidth: 1,
                  borderColor: '#CFD8DC',
                }}
              >
                <SingleText bold style={{ flex: 4 }}>
                  Service / Materials
                </SingleText>
                <SingleText bold style={{ flex: 1, textAlign: 'center' }}>
                  Quantity
                </SingleText>
                <SingleText bold style={{ flex: 1, textAlign: 'center' }}>
                  Unit Cost
                </SingleText>
                <SingleText bold style={{ flex: 1, textAlign: 'right' }}>
                  Line Total
                </SingleText>
              </View>
              {invoiceItems.map((_item, _i) => (
                <View
                  key={_i}
                  style={{
                    flexDirection: 'row',
                    padding: '12px 15px',
                    backgroundColor: 'var(--white)',
                    borderBottomWidth: 1,
                    borderColor: '#CFD8DC',
                  }}
                >
                  <SingleText style={{ flex: 4 }}>{_item.name}</SingleText>
                  <SingleText style={{ flex: 1, textAlign: 'center' }}>{_item.quantity}</SingleText>
                  <SingleText style={{ flex: 1, textAlign: 'center' }}>
                    {floatToDollar(_item.cost)}
                  </SingleText>
                  <SingleText style={{ flex: 1, textAlign: 'right' }}>
                    {floatToDollar(_item.total)}
                  </SingleText>
                </View>
              ))}
            </View>
            <View
              style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 30 }}
              wrap={false}
            >
              {invoiceInfo.additionalNoteEnable && (
                <View style={{ flex: 1, ...pdfStyle.part }}>
                  <SingleText bold style={{ marginBottom: 10 }}>
                    Additional Notes:
                  </SingleText>
                  <SingleText>{invoiceInfo.additionalNote}</SingleText>
                </View>
              )}
              <View style={{ width: 180, marginLeft: 10 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px 14px 14px',
                  }}
                >
                  <SingleText bold>Subtotal</SingleText>
                  <SingleText>{floatToDollar(invoiceInfo.subTotal)}</SingleText>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '0 14px 14px',
                  }}
                >
                  <SingleText bold>Sales Tax ({invoiceInfo.tax}%)</SingleText>
                  <SingleText>
                    {floatToDollar((invoiceInfo.subTotal * invoiceInfo.tax) / 100)}
                  </SingleText>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '10px 14px',
                    backgroundColor: 'var(--grey-3)',
                    borderRadius: 2,
                  }}
                >
                  <SingleText bold>Total</SingleText>
                  <SingleText>
                    {floatToDollar(
                      invoiceInfo.subTotal + (invoiceInfo.subTotal * invoiceInfo.tax) / 100,
                    )}
                  </SingleText>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 30 }} wrap={false}>
              <View style={{ flex: 3 }}>
                <SingleField
                  title="Customer Name"
                  content={invoiceInfo.customerName}
                  style={{ marginBottom: 30 }}
                />
                <SingleField title="Technician Name" content={invoiceInfo.technicianName} />
              </View>
              <View style={{ width: 30 }} />
              <View style={{ flex: 3 }}>
                <SingleField
                  title="Customer Signature"
                  content={invoiceInfo.customerSignature}
                  style={{ marginBottom: 30 }}
                />
                <SingleField
                  title="Technician Signature"
                  content={invoiceInfo.technicianSignature}
                />
              </View>
              <View style={{ width: 30 }} />
              <View style={{ flex: 2 }}>
                <SingleField
                  title="Date"
                  content={invoiceInfo.customerDate}
                  style={{ marginBottom: 30 }}
                />
                <SingleField title="Date" content={invoiceInfo.technicianDate} />
              </View>
            </View>
            {invoiceInfo.thankyouEnable && (
              <View
                style={{ flexDirection: 'row', justifyContent: 'center', paddingHorizontal: 30 }}
              >
                <View style={{ paddingHorizontal: 60, ...pdfStyle.part }}>
                  <SingleText style={{ textAlign: 'center' }}>{invoiceInfo.thankyou}</SingleText>
                </View>
              </View>
            )}
          </View>
          <View
            fixed
            style={{
              position: 'absolute',
              bottom: 0,
              height: 45,
              width: '100%',
              backgroundColor: invoiceInfo.invoiceColor,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image src={imgLogo} style={{ width: '149px', height: '25px' }} />
          </View>
        </Page>
      </Document>,
    ).toBlob();
    saveAs(blob, fileName);
  } catch (err) {
    console.log('Save pdf failed:', err);
  }
};

export default GeneratePdf;
